.builderItem {
  font-weight: 600;
  display: inline;
  margin-right: 8px;
  font-size: 0.8rem;
}

.builderItemActive {
  font-weight: 600;
  text-decoration: underline;
  display: inline;
  margin-right: 8px;
}
