.testText {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  display: block;
}
.canvasForeground {
  z-index: 10;
  display: block;
}

.testContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #f0f0f0;
}

.testInsert {
  overflow-y: auto;
  position: relative;
}

.sizeButton {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  opacity: 0.3;
}

.sizeButton:hover {
  opacity: 0.8;
}

.floatBottom {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  opacity: 0.3;
}
.floatBottom:hover {
  opacity: 0.8;
}
