.App-canvas-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    display: block;
    min-height: 350px;
  }
  .App-canvas {
    border: 1px solid lightgrey;
    position: relative;
    margin: auto;
    display: block;
  }
  .App-canvas-container:focus {
    outline: none;
  }
