.wordBackground {
  margin-top: 40px;
  margin-bottom: 80px;
}
.countDown {
  font-weight: 600;
}
div:focus {
  outline: none;
}
.wordTimerActionButton {
  margin-left: 20px;
  margin-right: 8px;
  padding: 10px;
  border-radius: 40px;
}
.testContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #f0f0f0;
}

.testInsert {
  overflow-y: auto;
  position: relative;
}

.sizeButton {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  opacity: 0.3;
}
.sizeButton:hover {
  opacity: 0.8;
}
