@media print {
  div.printThing {
    margin-top: 60px;
  }
  div.printThingSmall {
    margin-top: 60px;
    transform: scale(0.2);
  }
}

.buttonRow {
  margin-bottom: 4px;
}

.centreH4 {
  text-align: center;
}
