.sizeButton {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  opacity: 0.3;
}
.sizeButton:hover {
  opacity: 0.8;
}

.fullSizeBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1;
}

.fullSizeContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.fullSizeInsert {
  overflow-y: auto;
  position: relative;
  height: auto;
}
