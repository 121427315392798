.DevPath-main {
  height: 800px;
}

.pickerButton {
  padding: 0px;
  width: 50px;
  line-height: 50px;
  z-index: 2;
}

.pickerButton div {
  z-index: 0;
  border-radius: 4px;
  border: 1px solid grey;
}

.bigColourButton {
  padding: 0px;
  border: 1px solid grey;
  border-radius: 130px;
  width: 260px;
  line-height: 260px;
}
.menuList {
  list-style-type: none;
}
.menuItem {
  margin: 10px;
  display: inline-block;
}
.menuItem:hover {
  text-decoration: underline;
  cursor: pointer;
}
