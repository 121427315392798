.help {
  margin: 10px;
}

.helpBox {
  background-color: lightyellow;
  border: 1px solid darkblue;
  margin: 10px;
  padding: 10px;
  max-width: 380px;
}
