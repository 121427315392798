.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.shapeHolder {
  grid-column: 1;
  grid-row: 1 / 3;
}
.helpHolder {
  grid-column: 1;
}
.settingsHolder {
  grid-column: 2;
}
