.settings {
  margin: 10px;
}

.settingsBox {
  border: 1px solid darkblue;
  padding: 10px;
  padding-top: 0px;
  background-color: lightyellow;
  margin-top: 10px;
  max-width: 680px;
}
