span.text-center {
  text-align: center;
  width: 100%;
}
span.fontText {
  vertical-align: text-top;
  font-size: 0.6em;
}

.fontSizeAnnotation {
  position: absolute;
  margin-left: -80px;
  color: lightgray;
  vertical-align: text-bottom;
}
