.textSettingsBox {
  border: 1px solid darkblue;
  padding: 10px;
  padding-top: 0px;
  background-color: lightyellow;
  margin-top: 10px;
}
.colourPicker {
  width: 60px;
  height: 60px;
  padding: 0px;
}
